<template>
  <v-autocomplete
    v-if="!loading"
    v-model="selectedFornecedor"
    :items="fornecedores"
    :loading="buscandoFornecedores"
    :search-input.sync="searchFornecedor"
    @keyup="buscaFornecedores"
    :readonly="readonly"
    dense
    outlined
    hide-details
    item-text="nome"
    item-value="id"
    :placeholder="$tc('global.buscarFornecedor')"
    prepend-inner-icon="mdi-magnify"
    full-width
    :rules="obrigatorio ? formRules : []"
    :clearable="!readonly"
    return-object
  >
    <!-- :disabled="venda_items.length >= 1 || selectedProduto != null"
                :autofocus="!selectedFornecedor ? true : false" -->
  </v-autocomplete>
</template>

<script>
import { searchFornecedores } from "@/api/fornecedores/fornecedores.js";
import { inputRequired } from "@/helpers/utils.js";
export default {
  name: "SearchFornecedores",

  props: {
    fornecedor_async: {
      type: [Object, String],
    },
    fornecedor_id: {
      type: [Number, String],
      default: null,
    },
    fornecedor_nome: {
      type: [String],
      default: null,
    },
    obrigatorio: {
      type: [Boolean],
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {},

  data() {
    return {
      loading: true,
      selectedFornecedor: null,
      buscandoFornecedores: false,
      fornecedores: [],
      searchFornecedor: "",
      formRules: [inputRequired],
    };
  },
  watch: {
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.$emit("update:fornecedor_async", this.selectedFornecedor);
      } else {
        this.$emit("update:fornecedor_async", null);
      }
    },
  },
  computed: {},
  methods: {
    async searchFornecedores(filtro) {

      await searchFornecedores(filtro).then((response) => {
        this.fornecedores = response;
        if (this.fornecedor_id) {
          this.selectedFornecedor = this.fornecedores.find((item) => {
            return item.id == this.fornecedor_id;
          });
        }
      });
    },

    async buscaFornecedores(event) {
      if (this.searchFornecedor) {
        if (
          this.searchFornecedor.length > 2 &&
          event.key != "Backspace" &&
          event.key != "ArrowDown" &&
          event.key != "ArrowUp"
        ) {
          this.buscandoFornecedores = true;
          await this.searchFornecedores(`?search=${this.searchFornecedor}`);
          this.buscandoFornecedores = false;
        }
      }
    },
    // fetchFornecedores() {
    //   this.buscandoFornecedores = true;
    //   return getFornecedores()
    //     .then((response) => {
    //       this.fornecedores = response;
    //       this.buscandoFornecedores = false;
    //       if (this.fornecedor_id) {
    //         this.selectedFornecedor = this.fornecedores.find((item) => {
    //           return item.id == this.fornecedor_id;
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //
    //       this.buscandoFornecedores = false;
    //     });
    // },
  },
  async mounted() {
    if (this.fornecedor_nome) {
      await this.searchFornecedores(`?search=${this.fornecedor_nome}`);
    }
    this.loading = false;
  },
};
</script>

<style></style>
